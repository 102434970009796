import * as types from 'src/localization/types';

export const localized = (env: types.Env) => ({
  text: {
    label: env.localizer('components.InstitutionFilterV2.text.label'),
    placeholder: env.localizer('components.InstitutionFilterV2.text.placeholder')
  },
  type: {
    label: env.localizer('components.InstitutionFilterV2.type.label')
  },
  status: {
    label: env.localizer('components.InstitutionFilterV2.status.label')
  },
  loginCertificateType: {
    label: env.localizer('components.InstitutionFilterV2.loginCertificateType.label')
  }
});
