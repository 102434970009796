import React, { HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';

const Divider: React.FC<
  HTMLAttributes<HTMLElement> & { visibility?: 'visible' | 'hidden' }
> = ({ children, className, visibility = 'visible', ...rest }) => (
  <div
    className={cx(
      styles.divider,
      className,
      visibility === 'visible' ? styles.visible : styles.hidden
    )}
  >
    <div className={styles.line} />
    {children && (
      <>
        {React.Children.only(children)}
        <div className={styles.line} />
      </>
    )}
  </div>
);

export default Divider;
