import { css, cx } from '@emotion/css';
import { Theme, Color, Typography, Weight } from 'src/lib/theme/types';

export type Config = {
  color?: Color;
  weight?: Weight;
  variant?: Typography;
};

export const main =
  ({ color, weight = 'normal', variant = 'body' }: Config) =>
  (theme: Theme) => {
    return cx(
      color &&
        css`
          color: ${theme.color[color]};
        `,
      weight && theme.weight[weight],
      variant && theme.typography[variant]
    );
  };
