import { css } from '@emotion/css';

export const display = (style: string) =>
  css`
    display: ${style};
  `;

export const maxScreen = (max: string) => (style: string) =>
  css`
    @media screen and (max-width: ${max}) {
      ${style}
    }
  `;

export const minScreen = (min: string) => (style: string) =>
  css`
    @media screen and (min-width: ${min}) {
      ${style}
    }
  `;

export const pt = (cs: string) =>
  css`
    padding-top: ${cs};
  `;

export const pr = (cs: string) =>
  css`
    padding-right: ${cs};
  `;

export const pb = (cs: string) =>
  css`
    padding-bottom: ${cs};
  `;

export const pl = (cs: string) =>
  css`
    padding-left: ${cs};
  `;

export const mt = (cs: string) =>
  css`
    margin-top: ${cs};
  `;

export const mr = (cs: string) =>
  css`
    margin-right: ${cs};
  `;

export const mb = (cs: string) =>
  css`
    margin-bottom: ${cs};
  `;

export const ml = (cs: string) =>
  css`
    margin-left: ${cs};
  `;

export const borderColor = (cs: string) =>
  css`
    border-color: ${cs};
  `;

export const color = (cs: string) =>
  css`
    color: ${cs};
  `;

export const textAlign = (cs: string) =>
  css`
    text-align: ${cs};
  `;

export const flexDirection = (cs: string) =>
  css`
    flex-direction: ${cs};
  `;

export const justifyContent = (cs: string) =>
  css`
    justify-content: ${cs};
  `;
