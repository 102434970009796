import React from 'react';
import { cx } from '@emotion/css';
import { GetProps } from 'src/lib/react/types';
import * as styles from './styles';
import useTheme from 'src/hooks/useTheme';
import Navbar from 'src/components/Navbar';

const AppNavbar: React.FC<GetProps<typeof Navbar>> = ({ className, ...rest }) => {
  return <Navbar className={cx(styles.main(useTheme()), className)} {...rest} />;
};

export default AppNavbar;
