import React, { AnchorHTMLAttributes, Ref } from 'react';
import * as runtypes from 'runtypes';
import Text from './Text';
import Tertiary from './Tertiary';

export type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  variant?: 'text' | 'tertiary';
};

export const Variant = runtypes.Union(runtypes.Literal('text'), runtypes.Literal('tertiary'));

const components = Variant.match(
  () => Text,
  () => Tertiary
);

const Anchor: React.FC<Props & { innerRef: Ref<HTMLAnchorElement> }> = ({ variant, innerRef, ...props }) => {
  return React.createElement(components(variant || 'text'), { ref: innerRef, ...props });
};

export default React.forwardRef<HTMLAnchorElement, Props>((props, ref) => <Anchor {...props} innerRef={ref} />);
