import React, { HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';

export type Props = HTMLAttributes<HTMLDivElement> & {
  size: number;
}

const Column: React.FC<Props> = ({ className, ...rest }) => (
  <div
    className={cx(
      styles.main,
      className,
    )}
    {...rest}
  />
);

export default Column;
