import React, { forwardRef, Ref, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';

export type Props = HTMLAttributes<HTMLLIElement> & {
  innerRef: Ref<HTMLLIElement>;
  className?: string;
};

const Item: React.FC<Props> = ({ className, innerRef, ...rest }) => (
  <li className={cx(styles.main, className)} ref={innerRef} {...rest} />
);

export default forwardRef<HTMLLIElement, Omit<Props, 'innerRef'>>((props, innerRef) => (
  <Item innerRef={innerRef} {...props} />
));
