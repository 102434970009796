import { css } from '@emotion/css';

export const divider = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  color: #eee;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const line = css`
  width: 100%;
  height: 1px;
  background-color: #eee;
`;

export const hidden = css`
  visibility: hidden;
  margin: 16px 0;
`;

export const visible = css`
  margin: 16px 0;
`;
