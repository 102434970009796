import React, { HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';

const Well: React.FC<HTMLAttributes<HTMLElement>> = ({
  className,
  ...rest
}) => <div className={cx(styles.main, className)} {...rest} />;

export default Well;
