import * as runtypes from 'runtypes';
import * as locale from 'src/models/locale';

export const Config = runtypes.Record({
  locale: locale.types.Locale,
  locales: runtypes.Array(locale.types.Locale),
  namespace: runtypes.Array(runtypes.String)
});

export type Config = runtypes.Static<typeof Config>;
