import React, { forwardRef, Ref, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';

export type Props = HTMLAttributes<HTMLUListElement> & {
  innerRef: Ref<HTMLUListElement>;
  className?: string;
}

const Container: React.FC<Props> = ({
  innerRef,
  children,
  className,
  ...rest
}) => {
  return (
    <ul className={cx(styles.main, className)} ref={innerRef} {...rest}>
      {children}
    </ul>
  );
};

export default forwardRef<HTMLUListElement, Omit<Props, 'innerRef'>>((props, innerRef) => (
  <Container innerRef={innerRef} {...props} />
))
