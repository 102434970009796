import { css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = (theme: Theme) => css`
  text-decoration: none;
  cursor: pointer;
  color: ${theme.color.black};
  &:focus {
    color: ${theme.color.black};
  }
  &:hover {
    color: ${theme.color.black};
    text-decoration: underline;
  }
`;
