import * as types from 'src/localization/types';
import * as models from 'src/models';

export const localized: types.Localization<Record<models.institutionStatus.types.InstitutionStatus, string>> = (
  env
) => ({
  active: env.localizer('models.institutionStatus.active'),
  inactive: env.localizer('models.institutionStatus.inactive'),
  hidden: env.localizer('models.institutionStatus.hidden'),
  maintenance: env.localizer('models.institutionStatus.maintenance'),
  unsupported: env.localizer('models.institutionStatus.unsupported'),
  wontSupport: env.localizer('models.institutionStatus.wontSupport')
});
