import * as models from 'src/models';
import * as types from 'src/localization/types';

export const localized: types.Localization<Record<models.loginCertificateType.types.LoginCertificateType, string>> = (
  env
) => ({
  none: env.localizer('models.loginCertificateType.none'),
  optional: env.localizer('models.loginCertificateType.optional'),
  required: env.localizer('models.loginCertificateType.required')
});
