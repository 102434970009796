import * as types from '../types';

export const lookup =
  (key: string): types.Localization<string> =>
  (env) =>
    env.localizer(key);

export const localize =
  <A extends types.Definitions>(xs: A): types.Localization<types.Localized<A>> =>
  (env: types.Env) => {
    return Object.entries(xs).reduce(
      (acc, [key, value]) =>
        typeof value === 'object' ? { ...acc, [key]: localize(value)(env) } : { ...acc, [key]: lookup(value)(env) },
      {} as types.Localized<A>
    );
  };
