import { GetStaticPropsContext } from 'next';
import * as runtypes from 'runtypes';
import * as models from 'src/models';
import * as constants from './constants';
import * as types from './types';

export { types, constants };

export const fromStaticRoute = (data: { context: GetStaticPropsContext; namespace: string[] }): types.Config => {
  return {
    locale: models.locale.types.Locale.check(data.context.locale),
    locales: runtypes.Array(models.locale.types.Locale).check(data.context.locales || []),
    namespace: data.namespace
  };
};

export const fromLocales =
  (ls: models.locale.types.Locale[]) =>
  (m: types.Config): types.Config => {
    return {
      ...m,
      locales: ls
    };
  };
