import { css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = (theme: Theme) => css`
  border: none;
  background-color: ${theme.color.green10};
`;

export const header = (theme: Theme) => css`
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${theme.breakpoint.md.from}) {
    max-width: 750px;
  }
  @media (min-width: ${theme.breakpoint.lg.from}) {
    max-width: 970px;
  }
  @media (min-width: ${theme.breakpoint.xl.from}) {
    max-width: 1170px;
  }
`;

export const logo = () => css`
  width: 150px;
`;

export const locale = () => css`
  height: 100%;
`;

export const column = () => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const right = () => css`
  align-items: flex-end;
`;
