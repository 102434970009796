import { css, cx } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = (theme: Theme) =>
  cx(
    css`
      position: relative;
      color: ${theme.color.black};
      border: none;
      border-color: transparent;
      background: transparent;
      cursor: pointer;
      outline: none;
      transition: background 0.3s;
    `,
    theme.typography.button
  );
