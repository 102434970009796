import * as types from 'src/localization/types';
import * as models from 'src/models';

export const localized: types.Localization<{
  text: string;
  type: Record<models.institutionFilterV2.types.InstitutionFilterV2['type'], string>;
  status: Record<models.institutionFilterV2.types.InstitutionFilterV2['status'], string>;
}> = (env) => ({
  text: '',
  type: {
    unit: env.localizer('models.institutionFilterV2.type.unit'),
    bank: env.localizer('models.institutionFilterV2.type.bank'),
    credit_card: env.localizer('models.institutionFilterV2.type.credit_card'),
    stored_value: env.localizer('models.institutionFilterV2.type.stored_value'),
    point: env.localizer('models.institutionFilterV2.type.point'),
    corporate: env.localizer('models.institutionFilterV2.type.corporate'),
    stock: env.localizer('models.institutionFilterV2.type.stock')
  },
  status: {
    unit: env.localizer('models.institutionFilterV2.status.unit'),
    active: env.localizer('models.institutionFilterV2.status.active'),
    inactive: env.localizer('models.institutionFilterV2.status.inactive'),
    hidden: env.localizer('models.institutionFilterV2.status.hidden'),
    maintenance: env.localizer('models.institutionFilterV2.status.maintenance'),
    unsupported: env.localizer('models.institutionFilterV2.status.unsupported'),
    wontSupport: env.localizer('models.institutionFilterV2.status.wontSupport')
  }
});
