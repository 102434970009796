import { css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = (theme: Theme) => css`
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${theme.breakpoint.md.from}) {
    max-width: 750px;
  }
  @media (min-width: ${theme.breakpoint.lg.from}) {
    max-width: 970px;
  }
  @media (min-width: ${theme.breakpoint.xl.from}) {
    max-width: 1170px;
  }
`;

export const logo = () => css`
  max-width: 150px;
`;

export const locale = () => css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const right = () => css`
  align-items: flex-end;
`;
