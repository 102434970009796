import React, { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cx } from '@emotion/css';
import { GetProps } from 'src/lib/react/types';
import * as models from 'src/models';
import * as localization from 'src/localization';
import * as styles from './styles';
import useTheme from 'src/hooks/useTheme';
import useLocalized from 'src/hooks/useLocalized';
import useLocaleConfig from 'src/hooks/useLocaleConfig';
import Row from 'src/components/Row';
import Img from 'src/components/Img';
import Anchor from 'src/components/Anchor';

const AppHeader: React.FC<Omit<GetProps<typeof Row>, 'children'>> = ({ className, ...rest }) => {
  const router = useRouter();
  const config = useLocaleConfig();
  const toggled = useMemo(() => models.locale.toggle(config.locale), [config.locale]);
  const localized = useLocalized(localization.models.locale.localized);

  return (
    <Row className={cx(styles.main(useTheme()), className)} {...rest}>
      <Row.Column size={6}>
        <Link href="/" locale={config.locale} passHref>
          <Anchor variant="tertiary">
            <div className={styles.logo()}>
              <Img src="/images/moneytree/logo.png" alt="logo" />
            </div>
          </Anchor>
        </Link>
      </Row.Column>
      <Row.Column className={styles.right()} size={6}>
        {config.locales.length > 1 && (
          <Link href={router.asPath} locale={toggled} passHref>
            <Anchor className={styles.locale()} variant="tertiary">
              {localized[toggled]}
            </Anchor>
          </Link>
        )}
      </Row.Column>
    </Row>
  );
};

export default AppHeader;
