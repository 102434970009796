import { useContext, useMemo } from 'react';
import { BuildTimestampContext } from 'src/contexts/BuildTimestamp';

const useBuildDate = () => {
  const timestamp = useContext(BuildTimestampContext);
  const date = useMemo(() => new Date(timestamp), [timestamp]);

  return date;
};

export default useBuildDate;
