import React, { HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import useTheme from 'src/hooks/useTheme';
import * as styles from './styles';

const Footer: React.FC<HTMLAttributes<HTMLElement>> = ({ className, ...rest }) => (
  <footer className={cx(styles.main(useTheme()), className)} {...rest} />
);

export default Footer;
