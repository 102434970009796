import { useContext, useMemo } from 'react';
import { LocaleConfigContext } from 'src/contexts/LocaleConfig';
import * as configs from 'src/configs';

const useLocaleConfig = (override?: Partial<configs.locale.types.Config>) => {
  const localeConfig = useContext(LocaleConfigContext);
  return useMemo(() => ({ ...localeConfig, ...override }), [localeConfig, override]);
};

export default useLocaleConfig;
