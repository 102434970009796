import React, { HTMLAttributes } from 'react';
import { css, cx } from '@emotion/css';
import { clamp } from 'src/lib/math';
import { Color, Typography } from 'src/lib/theme/types';
import useTheme from 'src/hooks/useTheme';
import * as styles from './styles';

type Props = HTMLAttributes<HTMLSpanElement> & {
  level: number;
};

const Heading: React.FC<Props> = ({ className, level, ...rest }) => {
  const theme = useTheme();

  return (
    <span
      className={cx(
        styles.main,
        theme.typography[`h${clamp(level, 1, 4)}` as Typography] || theme.typography.h3,
        className
      )}
      {...rest}
    />
  );
};

export default Heading;
