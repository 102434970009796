import * as types from './types';

export { types };

export const rankByVariant: Record<types.Variant, number> = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4
};

export const variantByRank: Record<number, types.Variant> = {
  [rankByVariant.xs]: 'xs',
  [rankByVariant.sm]: 'sm',
  [rankByVariant.md]: 'md',
  [rankByVariant.lg]: 'lg',
  [rankByVariant.xl]: 'xl'
};

export const increment = (variant: types.BetweenVariant): Exclude<types.Variant, 'xs'> => {
  return variantByRank[rankByVariant[variant] + 1] as Exclude<types.Variant, 'xs'>;
};

export const decrement = (variant: types.BetweenVariant): Exclude<types.Variant, 'xl'> => {
  return variantByRank[rankByVariant[variant] - 1] as Exclude<types.Variant, 'xl'>;
};
