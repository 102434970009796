import React, { HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';

const Pipe: React.FC<Omit<HTMLAttributes<HTMLSpanElement>, 'children'>> = ({
  className,
  ...rest
}) => (
  <span {...rest} className={cx(styles.main, className)}>
    |
  </span>
);

export default Pipe;
