import React, { HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import { Typography } from 'src/lib/theme/types';
import useTheme from 'src/hooks/useTheme';

const Paragraph: React.FC<
  HTMLAttributes<HTMLParagraphElement> & {
    variant?: Typography;
  }
> = ({ className, variant = 'body', ...rest }) => (
  <p className={cx(useTheme().typography[variant], className)} {...rest} />
);

export default Paragraph;
