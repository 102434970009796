import React, { HTMLAttributes, useMemo } from 'react';
import { cx } from '@emotion/css';
import * as runtypes from 'runtypes';
import * as math from 'src/lib/math';
import * as styles from './styles';
import useTheme from 'src/hooks/useTheme';

export type Props = HTMLAttributes<HTMLElement> & {
  responsive?: styles.Responsive;
};

export type ChildProps = runtypes.Static<typeof ChildProps>;

export const ChildProps = runtypes.Record({
  size: runtypes.Number,
  className: runtypes.Optional(runtypes.String)
});

const Container: React.FC<Props> = ({ responsive, children, className, ...rest }) => {
  const theme = useTheme();
  const columns = useMemo(
    () =>
      React.Children.toArray(children).filter(
        (x): x is React.ReactElement<ChildProps> => React.isValidElement(x) && ChildProps.guard(x.props)
      ),
    [children]
  );
  const sum = useMemo(() => math.sum(columns.map((column) => column.props.size)), [columns]);

  return (
    <div className={cx(responsive ? styles.responsive(responsive)(theme) : styles.row(theme), className)} {...rest}>
      {columns.map((column, i) =>
        React.cloneElement(column, {
          ...column.props,
          key: i,
          className: cx(column.props.className, styles.ratio(column.props.size / sum))
        })
      )}
    </div>
  );
};

export default Container;
