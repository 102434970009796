import { css } from '@emotion/css';

export const main = css`
  min-height: 20px;
  padding: 19px;
  background-color: #f5f5f5;
  background-color: transparent;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
`;
