import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import useLocaleConfig from 'src/hooks/useLocaleConfig';
import * as configs from 'src/configs';
import * as localization from 'src/localization';

const useLocalized = <A>(fn: localization.types.Localization<A>, override?: configs.locale.types.Config): A => {
  const config = useLocaleConfig(override);
  const { t } = useTranslation(config.namespace);

  const localizer: localization.types.Env['localizer'] = useCallback(
    (key, interpolation) => {
      return t(key, { ...interpolation, ns: config.namespace });
    },
    [config.namespace]
  );

  return useMemo(() => fn({ localizer, locale: config.locale }), [fn, config.locale, localizer]);
};

export default useLocalized;
