import React, { Ref, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import useTheme from 'src/hooks/useTheme';
import * as styles from './styles';

export type Props = HTMLAttributes<HTMLSpanElement> & styles.Config;

const Text: React.FC<Props & { innerRef: Ref<HTMLSpanElement> }> = ({
  className,
  innerRef,
  color,
  weight,
  variant,
  ...rest
}) => {
  const theme = useTheme();
  return <span className={cx(styles.main({ color, weight, variant })(theme), className)} {...rest} />;
};

export default React.forwardRef<HTMLSpanElement, Props>((props, ref) => {
  return <Text innerRef={ref} {...props} />;
});
