import React, { Ref, AnchorHTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import useTheme from 'src/hooks/useTheme';
import * as styles from './styles';

export type Props = AnchorHTMLAttributes<HTMLAnchorElement>;

const Anchor: React.FC<Props & { innerRef: Ref<HTMLAnchorElement> }> = ({ className, innerRef, ...rest }) => {
  return <a className={cx(styles.main(useTheme()), className)} ref={innerRef} {...rest} />;
};

export default React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  return <Anchor innerRef={ref} {...props} />;
});
