import { css, cx } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';
import * as breakpoint from 'src/lib/breakpoint';
import * as cssClass from 'src/lib/cssClass';

export type Responsive = {
  at: breakpoint.types.BetweenVariant;
  reverse?: boolean;
};

export const responsive =
  ({ at, reverse = false }: Responsive) =>
  (theme: Theme) =>
    cx(
      cssClass.maxScreen(theme.breakpoint[at].to)(reverse ? columnReverse(theme) : column(theme)),
      cssClass.minScreen(theme.breakpoint[breakpoint.increment(at)].from)(row(theme))
    );

export const row = (_: Theme) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > :not(:last-child) {
    margin-right: 1.5em;
  }
`;

export const column = (_: Theme) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    width: 100% !important;
  }
`;

export const columnReverse = (_: Theme) => css`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  & > * {
    width: 100% !important;
  }
`;

export const ratio = (n: number) => css`
  width: ${n * 100}%;
`;
