import * as types from 'src/localization/types';
import * as models from 'src/models';

export const localized: types.Localization<Record<models.institutionTag.types.InstitutionTag, string>> = (env) => ({
  unit: env.localizer('models.institutionTag.unit'),
  bankPersonal: env.localizer('models.institutionTag.bankPersonal'),
  bankCorporate: env.localizer('models.institutionTag.bankCorporate'),
  creditCard: env.localizer('models.institutionTag.creditCard'),
  pointCard: env.localizer('models.institutionTag.pointCard'),
  stock: env.localizer('models.institutionTag.stock'),
  storedValue: env.localizer('models.institutionTag.storedValue'),
  active: env.localizer('models.institutionTag.active'),
  maintenance: env.localizer('models.institutionTag.maintenance'),
  willSupport: env.localizer('models.institutionTag.willSupport'),
  wontSupport: env.localizer('models.institutionTag.wontSupport')
});
