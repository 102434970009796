import { css } from '@emotion/css';
import { Theme } from 'src/lib/theme/types';

export const main = (theme: Theme) => css`
  border: none;
  text-align: center;
`;

export const container = () => css`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  & > :not(:last-child) {
    margin-bottom: 1px;
  }
`;

export const row = () => css`
  & > :not(:last-child) {
    margin-right: 5px;
  }
`;
