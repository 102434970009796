import * as types from 'src/localization/types';
import * as models from 'src/models';

export const create = (model: models.institution.types.Institution) => (env: types.Env) => {
  return {
    title: env.localizer('views.InstitutionDashboard.title', {
      service: env.localizer(`institutions.${model.entityKey}.display_name`)
    }),
    details: {
      title: env.localizer('views.InstitutionDashboard.details.title')
    },
    relatedArticles: {
      title: env.localizer('views.InstitutionDashboard.relatedArticles.title')
    },
    breadcrumbs: {
      institituions: env.localizer('views.InstitutionsOverview.title'),
      current: env.localizer('views.InstitutionDashboard.title', {
        service: env.localizer(`institutions.${model.entityKey}.display_name`)
      })
    }
  };
};
