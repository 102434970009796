import { useContext } from 'react';
import { MtLinksContext } from 'src/contexts/MtLinks';
import useLocale from 'src/hooks/useLocale';

const useMtLinks = () => {
  const locale = useLocale();
  const context = useContext(MtLinksContext);
  return context[locale];
};

export default useMtLinks;
