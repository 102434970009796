import * as models from 'src/models';
import * as types from 'src/localization/types';

export const create = (data: { date: Date; total: number }) => (env: types.Env) => ({
  title: env.localizer('views.InstitutionsOverview.title'),
  details: {
    explainer: env.localizer('views.InstitutionsOverview.details.explainer', {
      total: data.total,
      as_of: models.date.toLocalizedString(env.locale)(data.date)
    }),
    note: env.localizer('views.InstitutionsOverview.details.note')
  }
});
