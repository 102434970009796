import * as types from 'src/localization/types';
import * as models from 'src/models';

export const localized: types.Localization<Record<models.institutionType.types.InstitutionType, string>> = (env) => ({
  bank: env.localizer('models.institutionType.bank'),
  credit_card: env.localizer('models.institutionType.credit_card'),
  stored_value: env.localizer('models.institutionType.stored_value'),
  point: env.localizer('models.institutionType.point'),
  corporate: env.localizer('models.institutionType.corporate'),
  stock: env.localizer('models.institutionType.stock')
});
