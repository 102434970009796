import * as types from 'src/localization/types';
import * as localization from 'src/localization/models';
import * as models from 'src/models';

export const create = (model: models.institution.types.Institution) => (env: types.Env) => {
  const localized = {
    loginType: localization.loginType.localized(env),
    loginCertificateType: localization.loginCertificateType.localized(env),
    status: localization.institutionStatus.localized(env),
    type: localization.institutionType.localized(env)
  };

  return {
    name: model.displayName || env.localizer(`institutions.${model.entityKey}.display_name`),
    type: localized.type[model.institutionType],
    status: localized.status[models.institution.toStatus(model)],
    loginTypes: models.institution.toLoginTypes(model).map((loginType) => localized.loginType[loginType]),
    loginCertificateType: localized.loginCertificateType[models.institution.toLoginCertificateType(model)]
  };
};
