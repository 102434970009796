import React, { forwardRef, Ref, ImgHTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import * as styles from './styles';

export type Props = ImgHTMLAttributes<HTMLImageElement>;

const Img: React.FC<Props & { innerRef: Ref<HTMLImageElement> }> = ({ className, innerRef, ...rest }) => {
  return <img className={cx(styles.main, className)} ref={innerRef} {...rest} />;
};

export default forwardRef<HTMLImageElement, Props>((props, ref) => {
  return <Img innerRef={ref} {...props} />;
});
