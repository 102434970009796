import React from 'react';
import Link from 'next/link';
import { cx } from '@emotion/css';
import { GetProps } from 'src/lib/react/types';
import * as styles from './styles';
import * as localization from 'src/localization';
import useMtLinks from 'src/hooks/useMtLinks';
import useLocalized from 'src/hooks/useLocalized';
import useBuildDate from 'src/hooks/useBuildDate';
import useTheme from 'src/hooks/useTheme';
import Footer from 'src/components/Footer';
import Pipe from 'src/components/Pipe';
import Text from 'src/components/Text';
import Anchor from 'src/components/Anchor';

const AppFooter: React.FC<Omit<GetProps<typeof Footer>, 'children'>> = ({ className, ...rest }) => {
  const localized = useLocalized(localization.components.AppFooter.localized);
  const date = useBuildDate();
  const mtLinks = useMtLinks();

  return (
    <Footer {...rest} className={cx(className, styles.main(useTheme()))}>
      <div className={styles.container()}>
        <div className={styles.row()}>
          <Link href="/" passHref>
            <Anchor>
              <Text>{localized.services}</Text>
            </Anchor>
          </Link>
          <Pipe />
          <Anchor href={mtLinks.termsOfService}>
            <Text>{localized.tos}</Text>
          </Anchor>
          <Pipe />
          <Anchor href={mtLinks.privacy}>
            <Text>{localized.privacy}</Text>
          </Anchor>
        </div>
        <div className={styles.row()}>
          <Text>© 2014-{date.getFullYear()} Moneytree KK</Text>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
