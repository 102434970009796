import { css } from '@emotion/css';

export const main = css`
  position: relative;
  display: block;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-width: 1px 1px 1px 1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

