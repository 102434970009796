import * as types from 'src/localization/types';

export const create = (localeIdentifier: string) => (env: types.Env) => ({
  title: env.localizer(`views.ConnectionGuideMufgBankCorporate.title`, {
    service: env.localizer(`institutions.${localeIdentifier}.display_name`)
  }),
  breadcrumbs: {
    institutions: env.localizer('views.InstitutionsOverview.title'),
    institution: env.localizer('views.InstitutionDashboard.title', {
      service: env.localizer(`institutions.${localeIdentifier}.display_name`)
    }),
    current: env.localizer(`views.ConnectionGuideMufgBankCorporate.title`, {
      service: env.localizer(`institutions.${localeIdentifier}.display_name`)
    })
  }
});
