import { cx } from '@emotion/css';
import * as cssClass from 'src/lib/cssClass';
import * as localization from 'src/localization';
import useMtLinks from 'src/hooks/useMtLinks';
import useTheme from 'src/hooks/useTheme';
import useLocalized from 'src/hooks/useLocalized';
import Well from 'src/components/Well';
import Text from 'src/components/Text';
import Heading from 'src/components/Heading';
import Paragraph from 'src/components/Paragraph';
import Anchor from 'src/components/Anchor';

const HelpCreditCard = () => {
  const theme = useTheme();
  const mtLinks = useMtLinks();
  const localized = useLocalized(localization.components.HelpCreditCard.localized);

  return (
    <Well>
      <Heading level={4} className={cx(cssClass.color(theme.color.grey90), cssClass.mb('10px'))}>
        {localized.title}
      </Heading>
      <Paragraph className={cssClass.mb('10px')}>{localized.description}</Paragraph>
      <Anchor
        className={cx(cssClass.display('block'), cssClass.color(theme.color.green60))}
        href={mtLinks.faqCreditCard}
      >
        <Text variant="subtitle">{localized.btn}</Text>
      </Anchor>
    </Well>
  );
};

export default HelpCreditCard;
